/*
- 3.815rem
- 3.052rem
- 2.441rem
- 1.953rem
- 1.563rem
- 1.25rem
- 1rem
- 0.8rem
- 0.64rem
- 0.512rem
- 0.41rem
- 0.328rem
- 0.262rem
- 0.209rem
*/

:root {

  --secondary: #ff2e88;
  --light-secondary: #fc77b1;
  --red: red;
  --black: #212529;
  --white: #fdfdfd;
  --dark-gray: #343334;
  --light-green: #EEFFF2;
  --light-gray: #ccc;
  --lighter-gray: #f6f6f6;

  --green-lightest: #EBFEF5;
  --green-light: #CAFBE4;
  --green: #88EFBE;
  --green-dark: #62C395;
  --green-darkest: #348B5C;
  --visited: #adb9b2;
  
  --red-lightest: #FEEBEB;
  --red-light: #FBCACA;
  --red: #EF8888;
  --red-dark: #D46868;
  --red-darkest: #C73E3E;

  --visited: #adb9b2;

  --primary: var(--green);
  --light-primary: var(--green-light);

  --font-sans-serif:
    system-ui,
    -apple-system,
    segoe ui,
    roboto,
    ubuntu,
    helvetica,
    cantarell,
    noto sans,
    sans-serif;
  --font-monospace:
    menlo,
    monaco,
    lucida console,
    liberation mono,
    dejavu sans mono,
    bitstream vera sans mono,
    courier new,
    monospace,
    serif;
  --border-radius: 0.2rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  font-family: var(--font-sans-serif);
}

html {
  font-size: calc(16px);
  padding: 0;
  text-decoration-skip-ink: "auto";
  line-height: 1.4rem;
  margin: auto;
  min-height: 100%;
  overflow-x: hidden;
}

body {
  padding: 0;
  background-color: var(--white);
  color: var(--black);
  caret-color: var(--black);
  word-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  margin-top: 1em;
  font-weight: bold;
}

/* h1 {
  font-size: 3.052rem;
  letter-spacing: -0.15rem;
  line-height: 1;
} */

/* h1 {
  font-size: 2.441rem;
  letter-spacing: -0.12rem;
  line-height: 1.2;
} */

h1 {
  font-size: 1.953rem;
  letter-spacing: -0.09rem;
  line-height: 1.2;
}

h2 {
  font-size: 1.563rem;
  letter-spacing: -0.06rem;
  line-height: 1.3;
}

h3 {
  font-size: 1.25rem;
  letter-spacing: -0.03rem;
  line-height: 1.4;
}

h4 {
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5;
}

p {
  margin-bottom: 1.563rem;
}

p > *:last-child {
  margin-bottom: 0;
}

blockquote {
  border-left: 1px solid var(--light-gray);
  padding: 0 1rem;
  margin-bottom: 1.563rem;
}

a {
  color: var(--green-darkest);
  text-decoration: none;
}

.feed-link a:visited {
  color: var(--visited);
}

@media (hover: hover) {
  a:hover {
    text-decoration: underline;
  }
}

small {
  font-size: 0.888rem;
}

hr {
  border: 0;
  height: 2px;
  margin: 1rem 0;
  background: var(--light-gray);
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

label,
legend {
  font-weight: bold;
  display: inline-block;
}

input[type="email"],
input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime"],
input[type="datetime-local"],
input[type="url"],
input[type="search"],
input[type="tel"],
input:not([type]) {
  display: block;
  padding: 0.5rem;
  font-size: 1rem;
  border: 2px solid var(--lighter-gray);
  color: var(--black);
  appearance: none;
  border-radius: var(--border-radius);
  background-color: var(--lighter-gray);
  -webkit-appearance: none;
  -moz-appearance: none;
}

select {
  display: block;
  padding: 1rem;
  font-size: 1em;
  border: 2px solid var(--lighter-gray);
  border-radius: var(--border-radius);
  color: var(--black);
  background-color: var(--lighter-gray);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

textarea {
  display: block;
  font-size: 1rem;
  padding: 0.5rem;
  line-height: 1rem;
  color: var(--black);
  border-radius: var(--border-radius);
  border: 2px solid var(--lighter-gray);
  background-color: var(--lighter-gray);
  box-sizing: border-box;
  resize: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  border: 2px solid var(--primary);
}

input:invalid,
select:invalid,
textarea:invalid {
  border: 2px solid var(--red);
  outline: none;
}

input[type="checkbox"]:hover,
input[type="radio"]:hover {
  cursor: pointer;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  padding: 0.5rem 1.25rem;
  font-size: 1rem;
  border: 2px solid var(--green-darkest);
  border-radius: 2px;
  color: var(--green-darkest);
  height: 2.5rem;
  background-color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-weight: bold;
  margin-left: 20px;
  display: flex;
}

button:hover{
  background-color: var(--green);
  color: var(--white);
}

/* button.secondary{
 color: var(--gray)
} */
button.primary{
  background-color: var(--green-darkest);
  border-color: var(--green-darkest);
  color: var(--white);
}

button.primary:hover{
  background-color: var(--green);
  color: var(--green-darkest);
  /* border-color: var(--red-darkest); */
}

button.disabled, button.disabled:hover{
  background-color: var(--lighter-gray);
  border-color: var(--light-gray);
  color: var(--light-gray);
  cursor: default;
}

button.negative{
  background-color: var(--red-lightest);
  border-color: var(--red-darkest);
  color: var(--red-darkest);
}

button.negative:hover{
  background-color: var(--red-darkest);
  /* border-color: var(--red-darkest); */
  color: var(--white);
}

button.borderless{
  border-color: transparent;
}

button.borderless:hover{
  background-color: inherit;
  color: var(--green-darkest);
  border-color: var(--green-darkest);
}

.icon-button{
  aspect-ratio: 1;
  border-radius: 50%;
  display: grid;
  place-items: center;
  border: 2px solid var(--green-darkest);
  padding: 0px;
  background-color: transparent;
  height: 30px;
  color: var(--green-darkest);
}
.icon-button:hover{
  color: white;
  background-color: var(--green-darkest);
}

.floating-button{
  position: absolute;
  top: 10px;
  right: 2px;
}

/* @media (hover: hover) {
  input[type="reset"]:hover,
  input[type="submit"]:hover,
  input[type="button"]:hover,
  button:hover {
    cursor: pointer;
    background-color: var(--light-primary);
  }
} */

button:focus-visible,
input[type="submit"]:focus-visible,
input[type="reset"]:focus-visible,
input[type="button"]:focus-visible {
  border-color: var(--light-primary);
  outline: none;
}

input[disabled],
button:disabled {
  background-color: var(--gray);
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 1.75rem 0;
  font-variant-numeric: tabular-nums;
}

th,
td {
  vertical-align: top;
  border-bottom: 2px solid var(--light-gray);
  line-height: 15px;
  padding: 15px;
}

th {
  font-weight: bold;
  text-align: left;
  color: var(--dark-gray);
}

code, pre{
  background-color: #eee;
}
/* code,
pre {
  font-family: var(--font-monospace);
  color: var(--dark-gray);
  background-color: var(--lighter-gray);
  font-size: 0.8rem;
  vertical-align: middle;
  overflow: scroll;
  border-radius: var(--border-radius);
}

code {
  white-space: nowrap;
  vertical-align: baseline;
  padding: 0 0.328rem;
}

pre {
  white-space: pre;
  margin: 0.262rem 0;
  padding: 0.64rem 1rem;
}

pre::after {
  content: " ";
} */

ul {
  margin: 0;
  padding: 0 1px;
  list-style: disc outside;
  font-variant-numeric: tabular-nums;
}

ol {
  list-style: decimal outside;
}

ol,
ul {
  padding-left: 1rem;
  margin-bottom: 1rem;
}

li {
  list-style-position: inside;
}

kbd {
  display: inline-block;
  padding: 0 0.328rem;
  font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace;
  font-size: 0.64rem;
  color: var(--dark-gray);
  vertical-align: middle;
  background-color: #f9f9f9;
  border: solid 1px #d8d8d8;
  border-bottom: solid 2px #a6a5a6;
  border-radius: 5px;
}

abbr {
  text-decoration: none;
  border-bottom: 2px dashed #949394;
}

@media (hover: hover) {
  abbr:hover {
    cursor: help;
  }
}












*, *:before, *:after
{
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}






.App {
  display: flex;
  flex:1;
  flex-direction: column;
  height: 100vh;
}

.card-category{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-category button{
margin: 10px 0;
}

.footer{
  height: 50px;
  background-color: var(--green-lightest);
  display: flex;
  justify-content: space-between;
  padding-left: 80px;
}

.footer-spaces{
  display: flex;
  height: 50px;
  margin: 0;
  padding: 0;
  flex-grow: 1;
  justify-items: center;
  align-items: center;
}
.footer-spaces ul{
  display: flex;
  height: 100%;
  margin: 0 10px 0 20px;
  padding: 0;
}
.footer-spaces li{
  list-style-type: none;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0 0.7rem;
  cursor: pointer;
  color: var(--green-darkest);
}

.footer-spaces li:hover{
  background-color: var(--green-light);
  text-decoration: dashed;
  color: var(--green-darkest);
}

.footer-list-item.selected{
  /* background-color: var(--green); */
  font-weight: bold;
  color: var(--green-darkest);
  border-top: solid 2px var(--green-darkest) ;
}

.footer-list-item:hover{
  background-color: green;
}

#add-lane{
  display: none;
  flex-direction: row;
  position: absolute;
  bottom: 50px;
  background-color: var(--green-lightest);
  border: 1px solid var(--green);
  width: 100%;
  left: 0;
  /* 
  color: var(--green);

  height: 30px;

  
  padding: 0;
  transform: translate(-50%, 0);
  display: none; */
}

#add-lane-legend{
  position: absolute;
  left: 50%;
}

.add-lane-segment{
  border: 1px solid transparent;
  text-align: calc(center - 10px);
  background-color: transparent;
  color:transparent;
  cursor: pointer;
}

.add-lane-segment:hover{
  border: 1px 0 1px 0 solid var(--green-darkest);
  text-align: center;
  background-color: var(--green-darkest);
  color: var(--white);
}

.add-lane-segment:first-child{
  text-align: left;
  padding-left: 20px;
}

.add-lane-segment:last-child{
  text-align: right;
  padding-right: 20px;
}

.footer:hover #add-lane{
  display: flex;
}

#tim{
  height: 50px;
  width: 85px;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  bottom: 0px;
}

#timBody{
  position: absolute;
  height: 63px;
  left: 20px;
  top: 12px;
}

#timFace{
  position: absolute;
  top: 36px;
  left: 37px;
  height: 12px;
}

.space{
  height: calc(100%);
  display: flex;
  overflow: hidden;
}

.lane{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-left: 1px solid var(--green-light);
  border-right: 1px solid var(--green-light);
}

.lane-empty{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card{
    display: flex;
  border-top: 1px solid var(--green-light);
  border-bottom: 1px solid var(--green-light);
  position: relative;
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  background-color: var(--white);
}

.card-expanded{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
}

.lane:last-of-type .card{
  border-bottom: none;
}


.card-separator{
  /* background-color:mintcream; */
  height: 100%;
  width:20px;

  display: flex;
  flex-direction: column;
}

.card-edit{
  /* position:relative; */
  width: 100%;
  background-color: aqua;
}

.card-edit input {
  width: 100%;
}

.card-edit textarea {
  width: 100%;
  height: 100%;
}

.card-display{
  background-color: transparent;
  height: 100%;
  width: calc(100% - 20px);
  position: relative;
  overflow-y: auto;
}

/* .card-display img{
  width: 100%;
} */

.card-iframe{
  height: 100%;    
  width: 100%;
  /* background-color: hotpink; */
}


.card-content li{
  /* font-size: 2vw; */
  cursor: pointer;
}

.card-rss-header{
  display: flex;
  align-items: center;
}

.card-rss-header svg{
  cursor: pointer;
  margin-right: 8px;
  color:var(--green-darkest);
}

.card-rss-header svg:hover{
  color:var(--green);
}

.card-rss-message{
  position: absolute;
    top: 50%;
    transform: translateX(-50%);
    left: 50%;
}

.edit-modal{
  position:absolute;
  background-color: blueviolet;
  width: 50%;
  height: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}

/* .card-add{
  background-color: aqua;
  position:absolute;
  top: calc(50% - 15px);
  right:-16px;
  z-index:10;
} */

button{
 cursor: pointer;
}

.side-button{
  flex: 1; 
  background-color: var(--green-lightest);
  padding: 0;
  height: 50%;
  width: 100%;
  border: 0;
  margin:0px;
  border-radius: 0px;
  border-bottom: 1px solid var(--green-dark);
  align-items: center;
  color: var(--green-darkest);
}

.side-button:hover{
  background-color: var(--green-darkest);
}

.side-button:last-child{
  border-bottom: 0px;
}

.bg-danger{
  background-color: rgb(255, 246, 245);
}

.bg-danger:hover{
  background-color: rgb(223, 172, 166);
}

.border-danger{
  border: 1px solid rgb(223, 172, 166);
}

.hidden-if-unhover{
  display: none;
}

.button-row{
  display: flex;
  justify-content: flex-end;
}

.w-100{
  width: 100%;
}
.w-50{
  width: 50%;
}

/* .card:hover .card-separator {
  width: 20px;
} */

/* .card:hover .card-display {
  width: calc(100% - 20px);
} */

.card:hover .hidden-if-unhover{
  display: inline-block;
}

.round-button{
  height: 30px;
  width: 30px;
  border: 0px;
  border-radius: 50%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.modal{
  /* Modal container styles */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */

  /* Card styles */
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

#spaceModal{
  max-width: 300px;
  max-height: 250px;
}

.settings-space{
  display: flex;
  align-items: center;
}

.settings-space:hover{
  background-color: var(--green-lightest);
}

.settings-space>div{
  flex-grow: 1;
  padding:10px 0;
}



.cardRSS{
  position: relative;
}
.bottomClickables{
  position: sticky;
  top: 1rem;
  display: flex;
  justify-content: end;
}